@import url('https://fonts.googleapis.com/css2?family=Questrial&display=swap');

body {
    font-size: 15px;
}

img {
    width: 100%;
    height: auto;
}

h1, h2, h3, h4, h5, h6 {
    font-weight: lighter;
    margin: 0;
}

.login__wrapper .MuiButton-root {
    padding: 1em;
}

.page__wrapper {
    display: flex;
    flex-grow: 1;
    padding: 1em;
    background: #fafafa;
}

.p__1{
    padding: 0.2em;
}

.p__2{
    padding: 0.5em;
}

.p__3{
    padding: 1em;
}

#root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

#root .navigation__container {
    position: relative;
}

.navigation__container button {
    margin-left: 0.2em;
    margin-right: 0.2em;
}

.columns__1,
.columns__2,
.columns__3,
.columns__4,
.columns__5,
.columns__6,
.columns__2_3,
.columns__1_3 {
    display: grid;
    gap: 1em;
    grid-gap: 1em;
    width: 100%;
    grid-auto-rows: min-content
}

.columns__1 {
    grid-template-columns: 1fr;
}

.columns__2 {
    grid-template-columns: 1fr 1fr;
}

.columns__3 {
    grid-template-columns: 1fr 1fr 1fr;
}

.columns__4 {
    grid-template-columns: 1fr 1fr 1fr 1fr;
}

.columns__5 {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}

.columns__6 {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
}

.columns__2_3 {
    grid-template-columns: 0.66fr 0.33fr;
}

.columns__1_3 {
    grid-template-columns: 0.33fr 0.66fr;
}

.login__wrapper {
    text-align: center;
    width: 100%;
}

.login__container {
    margin-top: 3em;
    margin-left: auto;
    margin-right: auto;
    text-align: left;
    width: 40vw;
    max-width: 500px;
    min-width: 345px;
}

.login__logo {
    width: 65%;
}

.login__footer {
    text-align: center;
    font-size: 0.8em;
    opacity: 0.5;
    padding-top: 1em;
}

.navigation__logo {
    position: relative;
    display: flex;
    font-weight: lighter;
    flex-grow: 1;
    margin-top: -2px;
    text-decoration: none;
    color: #fff;
    padding-left: 1.5em;
}

.navigation__logo img {
    position: absolute;
    width: 1.3em;
    left: 0;
    top: 0;
}

.navigation__logo:hover {
    text-decoration: none;
}

.navigation__logo b {
    letter-spacing: 2px;
    text-transform: uppercase;
}

.navigation__separator {
    height: 1em;
    margin-left: 0.75em;
    margin-right: 0.75em;
    margin-top: 0.2em;
    border-right: 1px solid rgba(255, 255, 255, 0.2);
}

.text__bold {
    font-weight: bold;
}

.text__muted {
    opacity: 0.5;
    font-size: 0.9em;
}

.text__danger{
    color: #e44d4d;
}

.text__plain{
    text-transform: capitalize!important;
}

a {
    color: #1976d2;
    text-decoration: none;
}

.table__block {
    padding: 0;
}

.table__header--small th {
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    background: #f9f9f9;
}

.block__heading {
    padding: 1.5em;
}

.block__heading--bordered {
    border-bottom: 1px solid rgba(224, 224, 224, 1);
}

.list__wrapper {
    max-height: 500px;
}

.list__wrapper--full {
    max-height: calc(100vh - 200px);
}

.not__found {
    padding: 3em;
    text-align: center;
    letter-spacing: 1px;
    opacity: 0.4;
}

.drawer__page {
    width: 100%;
    display: flex;
    justify-content: space-between;
    overflow: hidden;
}

.drawer__page .drawer > div {
    top: 64px;
    height: calc(100% - 65px);
}

.transactions__list {
    overflow: initial;
    width: 100%;
}

.transactions__list > div > .MuiToolbar-root{
    display: none;
}

.transactions__list > .MuiPaper-root{
    padding: 0!important;
}

.paper__footer{
    background: #fafafa;
    padding: 1em;
    text-align: right;
}

thead tr th b {
    white-space: pre;
}

.hide__scroll {
    overflow: hidden;
}

.gap__0 {
    gap: 0;
    grid-gap: 0;
}

.d-flex__justify {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.d-flex{
    display: flex;
    align-items: center;
}

.d__inline-block{
    display: inline-block;
}

.transactions__heading .MuiTabs-scroller button {
    padding-top: 2em;
    padding-bottom: 2em;
}

.transactions__heading .MuiTabs-indicator{
    display: none;
}

.tabbed__heading .Mui-selected,
.transactions__heading .Mui-selected {
    font-weight: bold;
}

.tabbed__heading {
    height: 25px;
}

.tabbed__heading .MuiTabs-indicator {
    display: none;
}

.w__100 {
    width: 100%;
}

.mr__3 {
    margin-right: 1em;
}

p {
    margin-bottom: 0;
}

.text__center {
    text-align: center;
}

.text__right{
    text-align: right;
}

b {
    margin-bottom: 0.5em;
}

.detail__field {
    line-height: 1.75em;
}

.amount__due {
    color: #e44d4d;
    font-weight: bold;
}

.white-space__pre {
    white-space: pre;
}

.pac-status {
    border-radius: 50%;
    display: inline-block;
    background: #95c3da;
    width: 0.75em;
    height: 0.75em;
    margin-right: 0.4em;
}

.pac-status.pac-status--error {
    background: #d44944;
}

.pac-status.pac-status--complete {
    background: #5ab45a;
}

.MuiChip-label {
    font-size: 0.9em;
}

.contact__content {
    width: 100%;
    line-height: 1em;
}

.contact__content svg {
    width: 0.7em;
    height: 0.7em;
}

.contact__content a {
    font-size: 0.9em;
}

.mr__1 {
    margin-right: 0.2em;
}

.mr__2 {
    margin-right: 0.5em;
}

.mr__3 {
    margin-right: 1em;
}

.gap__4 {
    gap: 1.5em;
    grid-gap: 1.5em;
}

.contact__link {
    position: relative;
    margin-bottom: 0.5em;
}

.contact__link svg {
    position: absolute;
    left: 0;
    top: 0;
}

.contact__link span {
    margin-left: 1.5em;
}

.table__grid th{
    background: #f9f9f9;
    padding-top: 0.2em;
    padding-bottom: 0.2em;
}

.table__grid th button{
    font-weight: bold;
    text-transform: capitalize;
    white-space: pre;
}

.process__button{
    width: 147px;
}

.MuiTableRow-footer td{
    padding: 0;
}

.MuiTableRow-footer .MuiToolbar-root p{
    margin-top: 0;
}

.w-100{
    width: 100%;
}

.nav__item{
    transition: 0.2s ease-in-out;
    text-transform: uppercase;
    color: #fff;
    padding: 0.75em;
    font-size: 0.875rem;
    border-radius: 3px;
}

.nav__item:hover{
    background-color: rgba(25, 118, 210, 0.04);
}

.nav__item.active{

}

.transaction__actions{
    min-width: 100px;
    text-align: right;
}

.inspection__count__and__revenue__report__table td {
    text-align: right;
}

.inspection__count__and__revenue__report__table td.left__align {
    text-align: left;
}

.backup-cards-table td, .backup-cards-table th {
    text-align: center;
}

@media(max-width: 1200px){
    .columns__2,
    .columns__2_3
    {
        grid-template-columns: 1fr;
    }

    .login__wrapper .columns__2{
        grid-template-columns: 1fr 1fr;
    }
}

@media(max-width: 768px){
    .columns__3
    {
        grid-template-columns: 1fr 1fr;
    }

    #root .process__toggle{
        display: block;
    }

    #root .process__toggle button{
        display: block;
        width: 100%;
        border: 1px solid rgba(0, 0, 0, 0.12);
        border-radius: 0;
    }

    .drawer__page .drawer > div {
        height: calc(100% - 99px);
    }
}

@media(max-width: 768px) and (min-width: 600px){
    .navigation__container .MuiToolbar-root{
        flex-direction: column;
        padding: 0;
    }

    .navigation__container .MuiToolbar-root > .MuiBox-root{
        background: #5a5a5a;
        width: 100%;
        text-align: center;
    }

    .navigation__container .navigation__logo{
        padding: 1.4em;
        padding-left: 48px;
        align-self: start;
    }

    .navigation__logo img{
        left: 26px;
        top: 21px;
    }

    .drawer__page .drawer > div{
        top: 99px;
    }
}

@media(max-width: 599px){
    .drawer__page .drawer > div{
        top: 56px;
        height: calc(100% - 56px);
    }

    .columns__3{
        grid-template-columns: 1fr;
    }

    .hide__mobile{
        display: none;
    }

    .tabbed__heading .MuiTabs-root{
        margin-left: auto;
        margin-right: auto;
    }

    .login__wrapper{
        min-width: auto;
    }

    .login__wrapper .columns__2{
        grid-template-columns: 1fr;
    }

    .navigation__logo{
        margin-top: 2px;
    }

    .navigation__logo img{
        top: 2px;
    }

    .navigation__logo h4{
        font-size: 0.9em;
    }
}